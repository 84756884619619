import React from "react";
import BaseLayout from "../components/baseLayout";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-link";
import HeroTitle from "../components/heroTitle";

export default function TeamPage() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//content/team//" } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            frontmatter {
              name
              jobDescription
              order
              path
              img {
                childImageSharp {
                  gatsbyImageData(width: 300, height: 300)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BaseLayout>
      <HeroTitle
        title="Our Team"
        subtitle="An inter-disciplinary team supporting a variety of decision-makers"
      />

      <div className="section container is-size-5 has-text-justified">
        <p>
          Our team has extensive{" "}
          <strong>
            knowledge and experience in the transformation of data into
            meaningful information
          </strong>
          , taking into account the needs of different categories of users.
        </p>
        <br />
        <p>
          From <strong>Olympic Sailing to Disaster Risk Reduction</strong>, our
          methodology and tools are able to strengthen the ability to{" "}
          <strong>
            observe the past, identify weather-related scenarios and plan future
            actions
          </strong>{" "}
          taking into account scientific, local and traditional knowledge.
        </p>
      </div>

      <div className="section container">
        <div className="columns is-multiline is-centered is-mobile">
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <Member node={node} key={index} />
          ))}
        </div>
      </div>
    </BaseLayout>
  );
}

function Member({ node }) {
  const path = node.frontmatter.path;
  const name = node.frontmatter.name;
  const jobDescription = node.frontmatter.jobDescription;
  const gatsbyImageData = node.frontmatter.img.childImageSharp.gatsbyImageData;

  return (
    <Link
      className="shadow round responsiveMargin fixedWidth has-text-centered"
      to={path}
    >
      <GatsbyImage image={gatsbyImageData} alt={name} />
      <div className="margin">
        <div className="title">{name}</div>
        <div className="subtitle">{jobDescription}</div>
      </div>
    </Link>
  );
}
